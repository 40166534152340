import * as z from "zod"

export const InvitationTokenDbModel = z.object({
  id: z.string(),
  workspaceId: z.string(),
  email: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  token: z.string(),
  usedBy: z.string().nullish(),
})
