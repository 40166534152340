import * as z from "zod"

export const WorkspaceDbModel = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  deleted: z.boolean(),
  slug: z.string().nullish(),
  featuresEnabled: z.string().array(),
})
