import * as z from "zod"

export const UserPasswordDbModel = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userId: z.string(),
  hash: z.string(),
  changeAtNextLogin: z.boolean(),
})
