import * as z from "zod"

export const UserApiTokenDbModel = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  lastUsed: z.coerce.date().nullish(),
  hint: z.string(),
  hash: z.string(),
  userId: z.string(),
})
