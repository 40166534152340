import * as z from "zod"

export const source_checkDbModel = z.object({
  package: z.string(),
  version: z.string(),
  key: z.string(),
  status: z.string(),
  description: z.string().nullish(),
  timestamp: z.coerce.date(),
})
