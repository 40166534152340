import * as z from "zod"

export const task_logDbModel = z.object({
  id: z.string(),
  level: z.string(),
  logger: z.string(),
  message: z.string(),
  sync_id: z.string(),
  task_id: z.string(),
  timestamp: z.coerce.date(),
})
