import * as z from "zod"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const EmailLogDbModel = z.object({
  id: z.string(),
  time: z.date(),
  email: jsonSchema,
  error: z.string().nullish(),
  previewUrl: z.string().nullish(),
  messageId: z.string().nullish(),
  status: z.string(),
})
