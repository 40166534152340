import * as z from "zod"

export const UserProfileDbModel = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  admin: z.boolean().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  loginProvider: z.string(),
  externalUsername: z.string().nullish(),
  externalId: z.string(),
})
